import * as React from "react";
import {styled, useTheme} from "@mui/material/styles";

function Waves(props) {
    const theme = useTheme();
    const background = theme.palette.background.default;
    return (
        <Svg
            height={255}
            viewBox="0 0 1920 255"
            width={1920}
            xmlns="http://www.w3.org/2000/svg"
            {...props}>
            <g fill={background} fillRule="evenodd">
                <path d="M1 429h1919V151c-166.16-34.99-239.42-35.807-349-3-97.71 29.252-239.34 60.489-401 30s-322.842-17.51-500 32-352.342 16.487-406 1C214.706 196.77 87.323 150.867-.646 167.923z" />
                <path
                    d="M1921 355H-50l8-126C26.658 144 112.389-60.648 532 47c410.85 105.4 524.5 72 689 56s641.75-81.972 729 152z"
                    opacity={0.1}
                />
                <path
                    d="M-82 160c213.265-59.69 427.962-134.624 784-81s464.75 14.146 590-20 347.66-92.088 628-20v342H-77z"
                    opacity={0.1}
                />
            </g>
        </Svg>
    );
}

export default Waves;

const Svg = styled("svg")(({theme}) => ({
    position: "absolute",
    left: "-10000%",
    right: "-10000%",
    top: "-9933%",
    bottom: "-10000%",
    margin: "auto auto",
    minWidth: "1000%",
    minHeight: "1000%",
    transform: "scale(0.1)",
    zIndex: 0,
    overflow: "hidden",
    [theme.breakpoints.down("md")]: {
        top: "-9920%"
    },
    [theme.breakpoints.down("sm")]: {
        top: "-9909%"
    }
}));
