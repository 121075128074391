import React from "react";
import {Box, Container, Grid} from "@mui/material";
import {
    ColumnWrapper,
    ContentBody,
    ContentSubHeader,
    Line,
    RootStyle,
    SubTitle,
    Title
} from "../components";
import {styled} from "@mui/material/styles";
import {MotionInView, varFadeInUp} from "../animate";
import ShapeA from "../shapes/shape-a";
import ShapeB from "../shapes/shape-b";
import ExchangeSvg from "./component/exchange";
import WalletSvg from "./component/wallet";
import GiftcardSvg from "./component/GiftcardSvg";

const Features = () => {
    return (
        <RootStyle id="features">
            <Container maxWidth="lg">
                <Grid container direction="column">
                    <Grid item>
                        <MotionInView variants={varFadeInUp}>
                            <ColumnWrapper>
                                <Title>Ecosystem Key Features</Title>
                                <Line />
                                <SubTitle>
                                    Listed Below are the key features of our
                                    ecosystem. Our development team is
                                    constantly working to make more amazing
                                    features available to our users.
                                </SubTitle>
                            </ColumnWrapper>
                        </MotionInView>
                    </Grid>
                    <Grid item container sx={{marginTop: "50px"}}>
                        <Grid item xs={12} md={6}>
                            <ItemWrapper>
                                <IconWrapper>
                                    <WalletSvg />
                                </IconWrapper>
                                <ContentSubHeader>
                                    Self Hosted Wallets :
                                </ContentSubHeader>
                                <ContentBody>
                                    Using custodial wallet providers, MetaFaceWorld
                                    provides wallet functionalities to
                                    registered users with unique receiving
                                    addresses.
                                </ContentBody>
                            </ItemWrapper>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <ItemWrapper>
                                <IconWrapper>
                                    <ExchangeSvg />
                                </IconWrapper>
                                <ContentSubHeader>
                                    Instant Trader and Exchange :
                                </ContentSubHeader>
                                <ContentBody>
                                    MetaFaceWorld supports buying and selling of
                                    Cryptocurrencies with direct bank transfer
                                    or payment gateways, with optional setting
                                    of exchange fees.
                                </ContentBody>
                            </ItemWrapper>
                        </Grid>
                        <Grid item justifyContent="center" sx={{width: "100%"}}>
                            <GiftItemWrapper>
                                <IconWrapper>
                                    <GiftcardSvg />
                                </IconWrapper>
                                <ContentSubHeader>
                                    Giftcard Exchange :
                                </ContentSubHeader>
                                <ContentBody>
                                    MetaFaceWorld supports giftcard marketplace
                                    listing, with checkout and payment
                                    fulfilment using the existing payment
                                    account of users.
                                </ContentBody>
                            </GiftItemWrapper>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
            <ShapeAContainer>
                <ShapeA />
            </ShapeAContainer>
            <ShapeBContainer>
                <ShapeB />
            </ShapeBContainer>
        </RootStyle>
    );
};

export default Features;

const IconWrapper = styled(Box)(({theme}) => ({
    border: `2px solid ${theme.palette.primary.main}`,
    width: 75,
    height: 75,
    borderRadius: theme.shape.borderRadiusSm,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    transform: "rotate(45deg)",
    background: "transparent",
    margin: "50px auto 30px",
    [theme.breakpoints.down("md")]: {
        margin: "30px auto 30px"
    },
    [theme.breakpoints.down("sm")]: {
        margin: "30px 0 30px"
    }
}));
const ItemWrapper = styled(Box)(({theme}) => ({
    display: "flex",
    flexDirection: "column",
    maxWidth: 520,
    textAlign: "center",
    [theme.breakpoints.down("md")]: {
        paddingBottom: 50,
        margin: "auto"
    },
    [theme.breakpoints.down("sm")]: {
        maxWidth: "100%",
        textAlign: "left"
    }
}));
const GiftItemWrapper = styled(ItemWrapper)(({theme}) => ({
    margin: "auto",
    paddingTop: "50px",
    textAlign: "center",
    [theme.breakpoints.down("md")]: {
        padding: 0
    },
    [theme.breakpoints.down("sm")]: {
        textAlign: "left"
    }
}));
const ShapeAContainer = styled(Box)(({theme}) => ({
    position: "absolute",
    top: "35%",
    left: "25%",
    [theme.breakpoints.down("md")]: {
        left: "-3%"
    }
}));
const ShapeBContainer = styled(Box)(({theme}) => ({
    position: "absolute",
    bottom: "15%",
    right: "10%",
    transform: "rotate(0deg)",
    [theme.breakpoints.down("md")]: {
        right: "-3%"
    }
}));
