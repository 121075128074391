import React, {useState} from "react";
import {
    Alert,
    Box,
    Button,
    CircularProgress,
    Container,
    Grid,
    Modal,
    TextareaAutosize,
    TextField,
    Typography
} from "@mui/material";
import {styled} from "@mui/material/styles";
import {
    CenterWrapper,
    ColumnWrapper,
    Line,
    RootStyle,
    SubTitle,
    Title
} from "../components";
import {Icon} from "@iconify/react";
import {MotionInView, varFadeInDown, varFadeInUp} from "../animate";
import emailjs from "@emailjs/browser";
import ShapeA from "../shapes/shape-a";
import ShapeB from "../shapes/shape-b";

const Contact = () => {
    const [isSuccess, setIsSuccess] = React.useState(false);
    const [isError, setIsError] = React.useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [toSend, setToSend] = useState({
        from_name: "",
        to_name: "",
        message: "",
        reply_to: ""
    });

    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setIsSuccess(false);
    };
    const onSubmit = (e) => {
        e.preventDefault();
        setIsLoading(true);
        emailjs
            .send(
                "service_r1r6lbl",
                "template_usb7qno",
                toSend,
                "user_aDy9zmHmI3p1ApddhIu6n"
            )
            .then((response) => {
                if (response.status >= 200 && response.status <= 299) {
                    setIsSuccess(true);
                    setIsLoading(false);
                } else {
                    setIsError(true);
                    setIsLoading(false);
                }
            })
            .catch(() => {
                setIsError(true);
                setIsLoading(false);
            });
    };

    const handleChange = (e) => {
        setToSend({...toSend, [e.target.name]: e.target.value});
    };
    return (
        <RootStyle id="contact">
            <Container maxWidth="lg">
                <Grid container direction="column">
                    <Grid item>
                        <MotionInView variants={varFadeInUp}>
                            <ColumnWrapper>
                                <Title>Get In Touch</Title>
                                <Line />
                                <SubTitle>
                                    Any question? Reach out to us and we will
                                    get back to you shortly.
                                </SubTitle>
                                <MotionInView variants={varFadeInUp}>
                                    <ContactContent>
                                        {contactItems.map((contactItem) => (
                                            <IconWrapper
                                                key={contactItem.id}
                                                onClick={() => {
                                                    window.open(
                                                        contactItem.webUrl,
                                                        "_blank"
                                                    );
                                                }}>
                                                <ContactIcon>
                                                    <ContactIcon
                                                        icon={contactItem.icon}
                                                    />
                                                </ContactIcon>
                                                <ContactText>
                                                    {contactItem.text}
                                                </ContactText>
                                            </IconWrapper>
                                        ))}
                                    </ContactContent>
                                </MotionInView>
                            </ColumnWrapper>
                        </MotionInView>
                    </Grid>
                    <Grid item>
                        <MotionInView variants={varFadeInDown}>
                            <ContactForm onSubmit={onSubmit}>
                                <CustomtextField
                                    id="outlined-basic"
                                    label="Name"
                                    variant="outlined"
                                    name="from_name"
                                    value={toSend.from_name}
                                    onChange={handleChange}
                                    required
                                />
                                <CustomtextField
                                    id="outlined"
                                    label="Email"
                                    variant="outlined"
                                    required
                                    type="Email"
                                    name="reply_to"
                                    value={toSend.reply_to}
                                    onChange={handleChange}
                                />
                                <ContactTextArea
                                    aria-label="get-in-touch"
                                    minRows={4}
                                    name="message"
                                    placeholder="Your message"
                                    value={toSend.message}
                                    onChange={handleChange}
                                />
                                <CenterWrapper>
                                    <ContactButton type="submit">
                                        Send Message{" "}
                                        {isLoading ? (
                                            <CircularProgress
                                                size={20}
                                                sx={{marginLeft: "10px"}}
                                            />
                                        ) : (
                                            <PaperPlane icon="fluent:send-20-regular" />
                                        )}
                                    </ContactButton>
                                </CenterWrapper>
                            </ContactForm>
                            <Modal
                                open={isSuccess}
                                onClose={handleClose}
                                aria-labelledby="modal-modal-title"
                                aria-describedby="modal-modal-description">
                                <ModalStyle>
                                    <Alert
                                        severity={isError ? "error" : "success"}
                                        sx={{
                                            height: "150px",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center"
                                        }}>
                                        {isError
                                            ? "Message failed to deliver"
                                            : "Message Sent Successfully"}
                                    </Alert>
                                </ModalStyle>
                            </Modal>
                        </MotionInView>
                    </Grid>
                </Grid>
            </Container>
            <ShapeAContainer>
                <ShapeA />
            </ShapeAContainer>
            <ShapeBContainer>
                <ShapeB />
            </ShapeBContainer>
        </RootStyle>
    );
};

export default Contact;

const IconWrapper = styled(Box)(({theme}) => ({
    display: "flex",
    marginBottom: 20,
    alignItems: "center",
    "&:not(:first-of-type)": {
        marginLeft: 20
    },
    [theme.breakpoints.down("md")]: {
        "&:not(:first-of-type)": {
            marginLeft: 0
        }
    }
}));
const ContactContent = styled(Box)(({theme}) => ({
    marginTop: 50,
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down("md")]: {
        marginTop: 20,
        flexDirection: "column"
    }
}));
const ContactForm = styled("form")(() => ({
    display: "flex",
    flexDirection: "column"
}));
const ModalStyle = styled(Box)(({theme}) => ({
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    borderRadius: theme.shape.borderRadiusMd,
    border: "2px solid transparent",
    boxShadow: 24,
    p: 4
}));
const ContactIcon = styled(Icon)(({theme}) => ({
    background: `linear-gradient(to right, ${theme.palette.primary.main} 0%,${theme.palette.primary.darker} 100%)`,
    color: "#fff",
    padding: 10,
    borderRadius: "50%",
    marginRight: 15,
    cursor: "pointer",
    fontSize: 50,
    boxShadow: theme.shadows[2],
    [theme.breakpoints.down("md")]: {
        marginBottom: 15
    },
    [theme.breakpoints.down("sm")]: {
        fontSize: 40
    }
}));
const CustomtextField = styled(TextField)(({theme}) => ({
    marginBottom: 20,
    textAlign: "center",
    "& input:valid + fieldset": {
        border: "none",
        borderBottom: `1px solid ${theme.palette.grey[500]}`,
        borderRadius: 0
    },
    "& input:invalid + fieldset": {
        border: "none",
        borderBottom: `1px solid ${theme.palette.grey[500]}`,
        borderRadius: 0
    },
    "& input:valid:focus + fieldset": {
        borderLeftWidth: 6,
        padding: "4px !important" // override inline-style
    },
    "& label": {
        fontSize: 14
    },
    "& input": {
        fontSize: 14
    }
}));
const ContactText = styled(Typography)(({theme}) => ({
    ...theme.palette.body2,
    color: theme.palette.grey[500]
}));
const ContactTextArea = styled(TextareaAutosize)(({theme}) => ({
    padding: 15,
    fontFamily: theme.typography.fontFamily,
    fontSize: 14,
    border: "none",
    borderBottom: `1px solid ${theme.palette.grey[500]}`,
    marginBottom: 40,
    background: theme.palette.background.default,
    "&:hover": {
        outline: "none",
        borderBottom: `1px solid ${theme.palette.text.primary}`
    },
    "&::placeholder": {
        color: theme.palette.grey[500]
    },
    "&:focus": {
        outline: "none"
    }
}));
export const ContactButton = styled(Button)(({theme}) => ({
    padding: "8px 22px",
    border: "none",
    background: theme.palette.primary.main,
    color: "#fff",
    letterSpacing: 1.3,
    fontSize: 14,
    height: 48,
    textTransform: "uppercase",
    textDecoration: "none",
    display: "flex",
    alignItems: "center",
    fontWeight: "600",
    transition: "0.4s all ease-in-out",
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.customShadows.primary,
    "&:not(:first-of-type)": {
        marginLeft: 15
    },
    "&:hover": {
        background: theme.palette.primary.dark,
        border: "none",
        transition: "0.4s ease-in-out"
    }
}));
const PaperPlane = styled(Icon)(({theme}) => ({
    color: "#fff",
    paddingLeft: 10,
    fontSize: 30,
    [theme.breakpoints.down("sm")]: {
        fontSize: 30
    }
}));
const ShapeAContainer = styled(Box)(({theme}) => ({
    position: "absolute",
    top: "3%",
    left: "45%",
    [theme.breakpoints.down("md")]: {
        top: 0,
        left: "40%"
    }
}));
const ShapeBContainer = styled(Box)(({theme}) => ({
    position: "absolute",
    bottom: "10%",
    right: "10%",
    transform: "rotate(90deg)",
    [theme.breakpoints.down("md")]: {
        bottom: "5%",
        right: "-3%"
    }
}));
const contactItems = [

    {
        id: 1,
        text: "Exchange@Metafaceworldroadmap.com",
        icon: "ant-design:mail-filled",
        webUrl: "mailto:exchange@Metafaceworldroadmap.com"
    },
    {
        id: 2,
        text: "Join us on telegram",
        icon: "cib:telegram-plane",
        webUrl: "https://t.me/MetaFaceWorld"
    }
];
