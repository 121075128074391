import React from "react";
import {Box, Card, Container, Grid, Typography} from "@mui/material";
import {
    CenterWrapper,
    ColumnWrapper,
    Line,
    PageButton,
    RootStyle,
    SubTitle,
    Title
} from "../components";
import {styled} from "@mui/material/styles";
import EasySvg from "./component/easy";
import SecureSvg from "./component/secure";
import RocketSvg from "./component/fast";
import {MotionInView, varFadeInUp} from "../animate";
import ShapeA from "../shapes/shape-a";
import ShapeB from "../shapes/shape-b";

const Services = () => {
    return (
        <RootStyle id="features">
            <Container maxWidth="lg">
                <Grid container direction="column">
                    <Grid item>
                        <MotionInView variants={varFadeInUp}>
                            <ColumnWrapper>
                                <Title>Our Best Features</Title>
                                <Line/>
                                <SubTitle>
                                    Together, our interconnected platforms form the MetaFaceWorld ecosystem, where
                                    individuals from diverse backgrounds unite, creativity flourishes, and connections
                                    thrive. We are dedicated to providing a safe environment for people from all walks
                                    of life, and any economic background to express themselves, connect with others, and
                                    flourish in our ecosystem in unique and meaningful ways.
                                </SubTitle>
                            </ColumnWrapper>
                        </MotionInView>
                    </Grid>
                    <Grid item container alignItems="center">
                        <Grid item xs={12} md={4}>
                            <MotionInView variants={varFadeInUp}>
                                <ServiceCard>
                                    <ServiceWrapper>
                                        <CenterWrapper>
                                            <EasySvg/>
                                        </CenterWrapper>
                                        <ServiceHeader>
                                            Easy to Use
                                        </ServiceHeader>
                                        <ServiceSubHeader>
                                            We have built a user friendly system
                                            for the convience of our users.
                                            Explore our system to see just how
                                            easy it is to use and understand
                                            MetaFaceWorld.
                                        </ServiceSubHeader>
                                    </ServiceWrapper>
                                </ServiceCard>
                            </MotionInView>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <MotionInView variants={varFadeInUp}>
                                <ServiceCard>
                                    <ServiceWrapper>
                                        <CenterWrapper>
                                            <SecureSvg/>
                                        </CenterWrapper>
                                        <ServiceHeader>
                                            Secure Transactions
                                        </ServiceHeader>
                                        <ServiceSubHeader>
                                            Our team has taken security precautions to the next level, with extra layers
                                            of protection, audited security, and the innovative &quot;Magic Carpet Protocol&quot;,
                                            which is designed to ensure we keep our promises by time-locking digital
                                            assets in the manner that is agreed to in each contract.
                                        </ServiceSubHeader>
                                    </ServiceWrapper>
                                </ServiceCard>
                            </MotionInView>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <MotionInView variants={varFadeInUp}>
                                <ServiceCard>
                                    <ServiceWrapper>
                                        <CenterWrapper>
                                            <RocketSvg/>
                                        </CenterWrapper>
                                        <ServiceHeader>
                                            Super Fast
                                        </ServiceHeader>
                                        <ServiceSubHeader>
                                            Our system performance have been
                                            greatly enhanced and optimised to
                                            ensure that all transactions goes on
                                            swiftly in and out of the system.
                                        </ServiceSubHeader>
                                    </ServiceWrapper>
                                </ServiceCard>
                            </MotionInView>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <CenterWrapper sx={{paddingTop: "50px"}}>
                        <PageButton href="/auth/login">Get A Wallet</PageButton>
                    </CenterWrapper>
                </Grid>
            </Container>
            <ShapeAContainer>
                <ShapeA/>
            </ShapeAContainer>
            <ShapeBContainer>
                <ShapeB/>
            </ShapeBContainer>
        </RootStyle>
    );
};

export default Services;

const ServiceWrapper = styled(ColumnWrapper)(({theme}) => ({
    maxWidth: 300,
    [theme.breakpoints.down("md")]: {
        maxWidth: "100%",
        paddingBottom: 50
    },
    [theme.breakpoints.down("sm")]: {
        paddingBottom: 30
    }
}));
const ServiceCard = styled(Card)(({theme}) => ({
    marginTop: 70,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    maxWidth: 350,
    padding: 50,
    minHeight: 300,
    boxShadow: theme.customShadows.z8,
    [theme.breakpoints.down("lg")]: {
        maxWidth: 300
    },
    [theme.breakpoints.down("md")]: {
        maxWidth: 600,
        margin: "40px auto 0px",
        height: "100%"
    },
    [theme.breakpoints.down("sm")]: {
        maxWidth: 400
    },
    "&:hover": {
        transform: "scale(1.1)",
        cursor: "pointer",
        boxShadow: theme.customShadows.z12,
        transition: "0.4s all ease"
    }
}));
const ServiceHeader = styled(Typography)(({theme}) => ({
    ...theme.typography.h5,
    paddingTop: 20,
    color: theme.palette.text.primary,
    textAlign: "center"
}));
const ServiceSubHeader = styled(Typography)(({theme}) => ({
    ...theme.typography.body2,
    color: theme.palette.text.secondary,
    paddingTop: 5,
    textAlign: "center"
}));
const ShapeAContainer = styled(Box)(({theme}) => ({
    position: "absolute",
    top: "5%",
    left: "35%",
    [theme.breakpoints.down("md")]: {
        left: "-3%"
    }
}));
const ShapeBContainer = styled(Box)(({theme}) => ({
    position: "absolute",
    bottom: "5%",
    right: "10%",
    transform: "rotate(45deg)",
    [theme.breakpoints.down("md")]: {
        right: "-3%"
    }
}));
