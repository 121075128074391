import {styled} from "@mui/material/styles";
import {Box, Typography} from "@mui/material";
import {keyframes} from "@emotion/react";

export const RootStyle = styled(Box)(({theme}) => ({
    width: "100%",
    padding: "80px 0",
    position: "relative",
    boxSizing: "border-box",
    overflow: "hidden",
    [theme.breakpoints.down("md")]: {
        padding: "50px 0"
    }
}));

export const Title = styled(Typography)(({theme}) => ({
    ...theme.typography.h2,
    color: theme.palette.text.primary,
    textOverflow: "ellipsis",
    textAlign: "center",
    [theme.breakpoints.down("lg")]: {
        fontSize: 32
    },
    [theme.breakpoints.down("sm")]: {
        fontSize: 24
    }
}));

export const SideTitle = styled(Typography)(({theme}) => ({
    ...theme.typography.h2,
    textOverflow: "ellipsis",
    color: theme.palette.text.primary,
    [theme.breakpoints.down("lg")]: {
        fontSize: 32
    },
    [theme.breakpoints.down("md")]: {
        textAlign: "center",
        fontWeight: "bold"
    },
    [theme.breakpoints.down("sm")]: {
        fontSize: 24
    }
}));

export const SubTitle = styled(Typography)(({theme}) => ({
    ...theme.typography.subtitle1,
    textOverflow: "ellipsis",
    fontWeight: "500",
    color: theme.palette.text.secondary,
    paddingTop: 20,
    textAlign: "center"
}));

export const SideSubTitle = styled(Typography)(({theme}) => ({
    ...theme.typography.subtitle1,
    textOverflow: "ellipsis",
    fontWeight: "500",
    color: theme.palette.text.secondary,
    [theme.breakpoints.down("md")]: {
        textAlign: "center",
        paddingBottom: 20
    },
    [theme.breakpoints.down("sm")]: {
        paddingBottom: 40
    }
}));
export const ContentSubHeader = styled(Typography)(({theme}) => ({
    ...theme.typography.subtitle1,
    color: theme.palette.grey["600"],
    paddingBottom: 20,
    fontWeight: 500
}));
export const ContentBody = styled(Typography)(({theme}) => ({
    ...theme.typography.body1,
    color: theme.palette.grey["500"]
}));

export const CenterWrapper = styled(Box)(() => ({
    display: "flex",
    justifyContent: "center"
}));

export const ColumnWrapper = styled(Box)(() => ({
    display: "flex",
    flexDirection: "column"
}));
export const Svg = styled("svg")(() => ({
    height: 50,
    width: 50,
    transform: "rotate(-45deg)"
}));
export const Line = styled(Box)(({theme}) => ({
    width: 130,
    height: 5,
    borderRadius: theme.shape.borderRadiusLg,
    background: theme.palette.primary.main,
    margin: "30px auto"
}));
export const PageButton = styled("a")(({theme}) => ({
    padding: "8px 22px",
    border: "none",
    background: theme.palette.primary.main,
    color: "#fff",
    letterSpacing: 1.3,
    fontSize: 14,
    height: 48,
    textTransform: "uppercase",
    textDecoration: "none",
    display: "flex",
    alignItems: "center",
    fontWeight: "600",
    transition: "0.4s all ease-in-out",
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.customShadows.primary,
    "&:not(:first-of-type)": {
        marginLeft: 15
    },
    "&:hover": {
        background: theme.palette.primary.dark,
        border: "none",
        transition: "0.4s ease-in-out"
    }
}));
export const ImageAnimation = styled(Box)(({theme}) => ({
    position: "absolute",
    height: 500,
    width: 500,
    left: "40%",
    top: "50%",
    zIndex: 1,
    "&::before, &::after": {
        position: "absolute",
        content: "''",
        border: "4px solid rgba(151, 175, 213, 0.3)",
        borderRadius: "50%",
        boxSizing: "border-box"
    },
    "&::before": {
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        borderTopColor: theme.palette.primary.dark,
        borderRightColor: theme.palette.primary.dark,
        animation: `${rotate} 15s linear infinite 0s;`
    },
    "&::after": {
        top: 5,
        left: 5,
        bottom: 85,
        right: 85,
        borderTopColor: theme.palette.primary.main,
        borderBottomColor: theme.palette.primary.main,
        animation: `${reverseRotate} 10s linear infinite 0s;`
    },
    [theme.breakpoints.down("lg")]: {
        height: 400,
        width: 400
    },
    [theme.breakpoints.down("md")]: {
        left: "50%",
        height: 500,
        width: 500,
        top: "60%"
    },
    [theme.breakpoints.down("sm")]: {
        height: 320,
        width: 320,
        top: "67%"
    }
}));

const rotate = keyframes`
  from {
    transform: translate(-50%, -50%) rotate(
            0deg)
  }
  to {
    transform: translate(-50%, -50%) rotate(
            360deg)
  }
`;
const reverseRotate = keyframes`
  from {
    transform: translate(-50%, -50%) rotate(
            360deg)
  }
  to {
    transform: translate(-50%, -50%) rotate(
            0deg)
  }
`;
