import React from "react";
import {Box, Container, Grid} from "@mui/material";
import {
    ColumnWrapper,
    ContentBody,
    Line,
    RootStyle,
    SideSubTitle,
    SideTitle
} from "../components";
import {styled} from "@mui/material/styles";
import {MotionInView, varFadeInUp} from "../animate";
import ShapeA from "../shapes/shape-a";
import ShapeB from "../shapes/shape-b";
import {Icon} from "@iconify/react";
import exploreLight from "../../../assets/images/exploreLight.png";
import exploreDark from "../../../assets/images/exploreDark.png";
import useSettings from "../../../hooks/useSettings";

const Explore = () => {
    const {themeMode} = useSettings();
    const isLight = themeMode === "light";
    return (
        <RootStyle id="features">
            <Container maxWidth="lg">
                <Grid container direction="column">
                    <Grid item container alignItems="center">
                        <Grid item xs={12} md={5}>
                            <MotionInView variants={varFadeInUp}>
                                <ContentWrapper>
                                    <SideTitle>
                                        The MetaFaceWorld Community
                                    </SideTitle>
                                    <Box
                                        sx={{
                                            display: {xs: "block", md: "none"}
                                        }}>
                                        <Line />
                                    </Box>
                                    <SideSubTitle
                                        sx={{
                                            marginTop: "10px",
                                            fontWeight: 400
                                        }}>
                                        The MetaFaceWorld ecosystem offers a range of features designed to connect individuals world wide, online and in person.
                                    </SideSubTitle>
                                </ContentWrapper>
                            </MotionInView>
                        </Grid>
                        <Grid item xs={12} md={7}>
                            <MotionInView variants={varFadeInUp}>
                                <ImageWrapper>
                                    {isLight ? (
                                        <Image
                                            src={exploreLight}
                                            alt="pc-tab-phone"
                                        />
                                    ) : (
                                        <Image
                                            src={exploreDark}
                                            alt="pc-tab-phone"
                                        />
                                    )}
                                </ImageWrapper>
                            </MotionInView>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <MotionInView variants={varFadeInUp}>
                            <ItemContainer>
                                {[
                                    "Global Community",
                                    "Privacy and Security",
                                    "Innovative RoadMap",
                                    "Rewards and Recognition",
                                    "Focused On Longevity Of The Brand",
                                    "Easily Customisable",
                                    "Contant and Continuous Improvement",
                                    "Part of the larger MetaFaceWorld Ecosystem"

                                ].map((items, i) => (
                                    <ItemWrapper key={i}>
                                        <IconWrapper>
                                            <ExploreIcon icon="typcn:tick" />
                                        </IconWrapper>
                                        <ContentBody>{items}</ContentBody>
                                    </ItemWrapper>
                                ))}
                            </ItemContainer>
                        </MotionInView>
                    </Grid>
                </Grid>
            </Container>
            <ShapeAContainer>
                <ShapeA />
            </ShapeAContainer>
            <ShapeBContainer>
                <ShapeB />
            </ShapeBContainer>
        </RootStyle>
    );
};

export default Explore;

const ContentWrapper = styled(ColumnWrapper)(({theme}) => ({
    padding: "100px 0",
    paddingRight: 50,
    maxWidth: 500,
    [theme.breakpoints.down("md")]: {
        padding: "40px 0",
        maxWidth: "100%"
    }
}));
const ItemContainer = styled(Box)(({theme}) => ({
    display: "flex",
    flexWrap: "wrap",
    flex: "0 0 33.33%",
    gap: 20,
    [theme.breakpoints.down("md")]: {
        flexDirection: "column",
        flex: "0 0 100%",
        gap: "0px 10px"
    }
}));
const ItemWrapper = styled(Box)(({theme}) => ({
    display: "flex",
    alignItems: "center",
    width: "calc(33.33% - 30px)",
    padding: 25,
    borderRadius: 4,
    border: `2px solid ${theme.palette.primary.lighter}`,

    [theme.breakpoints.down("md")]: {
        width: "100%",
        marginBottom: 30
    }
}));
const IconWrapper = styled(Box)(({theme}) => ({
    borderRadius: "50%",
    width: 35,
    height: 35,
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginRight: 20,
    border: `2px solid ${theme.palette.primary.main}`,
    "&::before": {
        content: "''",
        position: "absolute",
        background: theme.palette.primary.light,
        left: 0,
        top: 0,
        borderRadius: "inherit",
        width: "100%",
        height: "100%",
        transform: "scale(1.8)",
        opacity: 0.4
    }
}));
const ExploreIcon = styled(Icon)(({theme}) => ({
    color: theme.palette.primary.main,
    fontSize: 30
}));
const ImageWrapper = styled(Box)(({theme}) => ({
    maxWidth: "100%",
    [theme.breakpoints.down("md")]: {
        margin: "0 auto",
        paddingBottom: 40
    }
}));
const Image = styled("img")(() => ({
    width: "100%"
}));
const ShapeAContainer = styled(Box)(({theme}) => ({
    position: "absolute",
    top: "25%",
    left: "35%",
    [theme.breakpoints.down("md")]: {
        left: "-3%"
    }
}));
const ShapeBContainer = styled(Box)(({theme}) => ({
    position: "absolute",
    bottom: "5%",
    right: "10%",
    transform: "rotate(45deg)",
    [theme.breakpoints.down("md")]: {
        right: "-3%"
    }
}));
