import React from "react";
import Landing from "./landing";
import {BrowserRouter, Routes, Route} from "react-router-dom";
import ThemeConfig from "./theme";

function App() {
    return (
        <ThemeConfig>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Landing />} />
                </Routes>
            </BrowserRouter>
        </ThemeConfig>
    );
}

export default App;
