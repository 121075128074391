import * as React from "react";
import {useTheme} from "@mui/material/styles";
import {Svg} from "./easy";

function SecureSvg(props) {
    const theme = useTheme();
    const color = theme.palette.primary.main;
    return (
        <Svg
            xmlns="http://www.w3.org/2000/svg"
            width={32}
            height={32}
            viewBox="0 0 32 32"
            fill={color}
            {...props}>
            <path
                style={{
                    lineHeight: "normal",
                    textIndent: 0,
                    textAlign: "start",
                    textDecorationLine: "none",
                    textDecorationStyle: "solid",
                    textDecorationColor: "#000",
                    textTransform: "none",
                    blockProgression: "tb",
                    whiteSpace: "normal",
                    isolation: "auto",
                    mixBlendMode: "normal",
                    solidColor: "#000",
                    solidOpacity: 1
                }}
                fillRule="evenodd"
                d="M15.65 2.004a13.931 13.931 0 00-8.185 2.883c-5.56 4.265-7.098 12-3.594 18.068a.5.5 0 10.865-.5A12.965 12.965 0 018.074 5.68a12.96 12.96 0 0116.127.27h-.707a.5.5 0 100 1h1.979a.5.5 0 00.386-.153.5.5 0 00.032-.033.5.5 0 00.076-.135.5.5 0 00.015-.043.5.5 0 000-.004.5.5 0 00.01-.045A.5.5 0 0026 6.45V4.445a.5.5 0 00-.508-.508.5.5 0 00-.492.508v.873a13.942 13.942 0 00-9.35-3.314zM16 7.8c-2.37 0-4.295 1.916-4.295 4.28v1.22h-1.668a.5.5 0 00-.5.5v9.9a.5.5 0 00.5.5h11.926a.5.5 0 00.5-.5v-9.9a.5.5 0 00-.5-.5h-1.668v-1.22c0-2.364-1.925-4.28-4.295-4.28zm11.68.978a.5.5 0 00-.416.768 12.965 12.965 0 01-3.338 16.775 12.961 12.961 0 01-16.125-.27h.683a.5.5 0 100-1H6.563a.5.5 0 00-.182.01.5.5 0 00-.4.49v2.005a.5.5 0 101 0v-.891a13.941 13.941 0 008.136 3.305 13.94 13.94 0 009.418-2.856c5.56-4.265 7.098-12 3.594-18.068a.5.5 0 00-.45-.268zM16 8.801a3.269 3.269 0 013.295 3.28v1.22h-6.59v-1.22c0-1.826 1.46-3.28 3.295-3.28zm-5.463 5.5H21.463v8.9H10.537v-8.9zM16 16.094c-.927 0-1.693.757-1.693 1.683 0 .752.507 1.385 1.193 1.6v2.547h1v-2.547a1.688 1.688 0 001.193-1.6c0-.926-.766-1.683-1.693-1.683zm0 1c.39 0 .693.301.693.683a.683.683 0 01-.693.686.683.683 0 01-.693-.686c0-.382.303-.683.693-.683z"
                color={color}
                fontFamily="sans-serif"
                fontWeight={400}
                overflow="visible"
            />
        </Svg>
    );
}

export default SecureSvg;
