import React, {useCallback} from "react";
import {Box, Button, Container, Grid, Typography} from "@mui/material";
import {styled} from "@mui/material/styles";
import Particles from "react-particles";
import {loadFull} from "tsparticles";
import {Icon} from "@iconify/react";
import Waves from "./component/waves";
import ShapeA from "./component/shape-a";
import ShapeB from "./component/shape-b";

const Hero = () => {
    const particlesInit = useCallback(async (engine) => {
        await loadFull(engine);
    }, []);

    const particlesLoaded = useCallback(async (container) => {
    }, []);
    return (
        <RootStyle id="home">
            <ParticleStyle
                init={particlesInit}
                loaded={particlesLoaded}
                id="tsparticles"
                options={particleOptions}
            />
            <Container maxWidth="lg">
                <Grid container direction="column" alignItems="center">
                    <Grid item sx={{width: "100%"}}>
                        <BannerContent>
                            <BannerHeader>
                                Buy and sell Cryptocurrencies & Giftcard with
                                MetaFaceWorld!
                            </BannerHeader>
                            <BannerSubHeader>
                                A wide range of supported cryptocurrencies,
                                Clean and Elegant Design, Easy and Safe to use.
                            </BannerSubHeader>
                        </BannerContent>
                    </Grid>
                    <Grid item sx={{width: "100%"}}>
                        <HeroButtonWrapper>
                            <PageLink href="/auth/register">
                                <HeroButton variant="contained">
                                    {" "}
                                    Sign up
                                    <IconWrapper>
                                        <ButtonIcon icon="ic:round-keyboard-double-arrow-right"/>
                                    </IconWrapper>
                                </HeroButton>
                            </PageLink>
                            <PageLink href="/auth/login">
                                <HeroButton variant="contained">
                                    {" "}
                                    Login
                                    <IconWrapper>
                                        <ButtonIcon icon="ic:round-keyboard-double-arrow-right"/>
                                    </IconWrapper>
                                </HeroButton>
                            </PageLink>
                        </HeroButtonWrapper>
                        <HeroIconWrapper>
                            <HeroIcon icon="cryptocurrency:btcp"/>
                            <HeroIcon icon="cryptocurrency:etc"/>
                            <HeroIcon icon="cryptocurrency:ltc"/>
                            <HeroIcon icon="cryptocurrency:gto"/>
                        </HeroIconWrapper>
                    </Grid>
                </Grid>
            </Container>
            <SocialIconWrapper>
                <SocialLink href="https://www.facebook.com/MetaFaceWorld/">
                    <Social icon="brandico:facebook"/>
                </SocialLink>
                <SocialLink href="https://www.instagram.com/metafaceexchange/">
                    <Social icon="ant-design:instagram-filled"/>
                </SocialLink>
                <SocialLink href="https://twitter.com/MetaFace_World">
                    <Social icon="akar-icons:twitter-fill"/>
                </SocialLink>
                <SocialLink href="https://www.pinterest.com/metafaceexchange/">
                    <Social icon="mdi:pinterest"/>
                </SocialLink>
                <SocialLink href="https://discord.com/users/MetaFaceWorld">
                    <Social icon="ic:outline-discord"/>
                </SocialLink>
                <SocialLink href="https://www.tiktok.com/@metafaceworldexchange">
                    <Social icon="ic:round-tiktok"/>
                </SocialLink>
            </SocialIconWrapper>
            <ShapeAContainer>
                <ShapeA/>
            </ShapeAContainer>
            <ShapeBContainer>
                <ShapeB/>
            </ShapeBContainer>
            <Waves/>
        </RootStyle>
    );
};

export default Hero;

const RootStyle = styled(Box)(({theme}) => ({
    width: "100%",
    height: "100%",
    position: "relative",
    boxSizing: "border-box",
    paddingBottom: "100px",
    overflow: "hidden",
    background: `linear-gradient(to right, ${theme.palette.primary.main} 0%,${theme.palette.primary.darker} 100%)`,
    [theme.breakpoints.down("md")]: {
        height: "fit-content"
    }
}));
const ParticleStyle = styled(Particles)(() => ({
    width: "100%",
    height: "100%",
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 0
}));
const BannerContent = styled(Box)(() => ({
    display: "flex",
    flexDirection: "column",
    paddingTop: 120,
    maxWidth: 800,
    margin: "auto",
    position: "relative",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 2
}));
const BannerHeader = styled(Typography)(({theme}) => ({
    ...theme.typography.h1,
    color: "#fff",
    textAlign: "center",
    fontWeight: "700",
    paddingBottom: 50
}));
const BannerSubHeader = styled(Typography)(({theme}) => ({
    ...theme.typography.h5,
    color: "#fff",
    maxWidth: 600,
    margin: "auto",
    fontWeight: "400",
    textAlign: "center",
    paddingBottom: 60
}));
const HeroButtonWrapper = styled(Box)(() => ({
    position: "relative",
    display: "flex",
    justifyContent: "center",
    paddingBottom: 50,
    zIndex: 3
}));
const HeroButton = styled(Button)(({theme}) => ({
    padding: "12px 78px 12px 58px",
    color: theme.palette.primary.main,
    letterSpacing: 1.4,
    textTransform: "uppercase",
    fontWeight: "500",
    background: "#fff",
    borderRadius: theme.shape.borderRadiusLg,
    "&:hover": {
        cursor: "pointer",
        background: "#fff"
    },
    [theme.breakpoints.down("sm")]: {
        padding: "8px 48px 8px 12px",
        fontSize: 12
    }
}));
const PageLink = styled("a")(() => ({
    textDecoration: "none",
    "&:not(:first-of-type)": {
        marginLeft: 20
    }
}));
const HeroIconWrapper = styled(Box)(() => ({
    position: "relative",
    display: "flex",
    justifyContent: "center",
    paddingBottom: 150
}));
const HeroIcon = styled(Icon)(({theme}) => ({
    fontSize: 40,
    color: "#fff",
    "&:not(:first-of-type)": {
        marginLeft: 15
    },
    "&:hover": {
        color: theme.palette.primary.light,
        cursor: "pointer"
    },
    [theme.breakpoints.down("md")]: {
        fontSize: 30
    }
}));
const IconWrapper = styled(Box)(({theme}) => ({
    position: "absolute",
    borderRadius: "50%",
    background: theme.palette.primary.main,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: 12,
    right: "2%",
    [theme.breakpoints.down("sm")]: {
        padding: 7,
        right: "1%"
    }
}));
const SocialIconWrapper = styled(Box)(({theme}) => ({
    position: "absolute",
    background: "rgba(255,255,255,0.2)",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-around",
    padding: 10,
    borderRadius: theme.shape.borderRadiusLg,
    height: "fit-content",
    right: "5%",
    top: "24%",
    zIndex: 3,
    [theme.breakpoints.down("md")]: {
        flexDirection: "row",
        top: "75%",
        left: 0,
        right: 0,
        padding: "5px 10px",
        maxWidth: 400,
        margin: "auto"
    }
}));
const Social = styled(Icon)(() => ({
    opacity: 1
}));
const SocialLink = styled("a")(({theme}) => ({
    textDecoration: "none",
    color: "#fff",
    fontSize: 25,
    paddingBottom: 25,
    "&:first-of-type": {
        paddingTop: 25
    },
    "&:hover": {
        color: theme.palette.primary.light,
        transform: "translateY(-5px)",
        transition: "0.3s ease-in"
    },
    [theme.breakpoints.down("md")]: {
        padding: 0,
        "&:first-of-type": {
            paddingTop: 0
        }
    }
}));
const ButtonIcon = styled(Icon)(() => ({
    color: "#fff",
    fontSize: 20
}));
const ShapeAContainer = styled(Box)(({theme}) => ({
    position: "absolute",
    top: "20%",
    left: "13%",
    [theme.breakpoints.down("md")]: {
        top: "5%",
        left: "-3%"
    }
}));
const ShapeBContainer = styled(Box)(({theme}) => ({
    position: "absolute",
    bottom: "30%",
    right: "10%",
    [theme.breakpoints.down("md")]: {
        bottom: "5%",
        right: "-3%"
    }
}));

const particleOptions = {
    fullScreen: {
        enable: false
    },
    fpsLimit: 60,
    interactivity: {
        events: {
            onClick: {
                enable: true,
                mode: "push"
            },
            onHover: {
                enable: true,
                mode: "repulse"
            },
            resize: true
        },
        modes: {
            bubble: {
                distance: 400,
                duration: 2,
                opacity: 0.8,
                size: 40
            },
            push: {
                quantity: 4
            },
            repulse: {
                distance: 200,
                duration: 0.4
            }
        }
    },
    particles: {
        color: "rgba(255, 255, 255, 0.3)",
        links: {
            color: "rgba(255, 255, 255, 0.3)",
            distance: 200,
            enable: true,
            opacity: 0.5,
            width: 1
        },
        move: {
            direction: "none",
            enable: true,
            outMode: "bounce",
            random: false,
            speed: 3,
            straight: false
        },
        number: {
            density: {
                enable: true,
                area: 1000
            },
            value: 40
        },
        opacity: {
            value: 0.4
        },
        shape: {
            type: "circle"
        },
        size: {
            random: true,
            value: 5
        }
    }
};
