import React from "react";
import {Drawer, List, ListItem, ListItemIcon, Box} from "@mui/material";
import {NavButton, navItems} from "../header";
import {Link as ScrollLink} from "react-scroll";
import {Close} from "@mui/icons-material";
import {styled} from "@mui/material/styles";

const Sidebar = ({open, toggle}) => {
    const drawerWidth = 400;

    return (
        <Drawer
            anchor="left"
            open={open}
            onClose={toggle}
            sx={{
                "& .MuiDrawer-paper": {
                    boxSizing: "border-box",
                    width: drawerWidth
                }
            }}>
            <List>
                <SideBarListItem onClick={toggle}>
                    <ListItemIcon sx={{minWidth: "auto"}}>
                        <CloseIcon />
                    </ListItemIcon>
                </SideBarListItem>
                <Box>
                    {navItems.map((sideItem) => (
                        <ListItem
                            key={sideItem.id}
                            sx={{flexDirection: "column"}}>
                            <ListItemLink
                                to={sideItem.idm}
                                smooth={true}
                                duration={500}
                                spy={true}
                                exact="true"
                                offset={-80}
                                onClick={toggle}>
                                {sideItem.name}
                            </ListItemLink>
                        </ListItem>
                    ))}
                </Box>
            </List>
            <SideBarButtonWrapper>
                <PageLink href="/auth/register">
                    <SideButton variant="outlined">Sign up</SideButton>
                </PageLink>
                <PageLink href="/auth/login">
                    <SideButton variant="outlined">Login</SideButton>
                </PageLink>
            </SideBarButtonWrapper>
        </Drawer>
    );
};

export default Sidebar;
const SideBarListItem = styled(ListItem)(() => ({
    justifyContent: "flex-end",
    cursor: "pointer"
}));
const CloseIcon = styled(Close)(({theme}) => ({
    fontSize: "30px",
    color: theme.palette.primary.main
}));
const ListItemLink = styled(ScrollLink)(({theme}) => ({
    fontFamily: theme.typography.fontFamily,
    position: "relative",
    padding: "25px 0px",
    color: theme.palette.text.white,
    cursor: "pointer",
    fontSize: 14,
    "&.active": {
        color: theme.palette.primary.dark,
        "&::before": {
            content: "''",
            display: "block",
            position: "absolute",
            left: 0,
            bottom: 0,
            width: "100%",
            height: "1.4px",
            background: theme.palette.primary.main,
            borderRadius: "5px",
            transition: "all .5s ease-out"
        }
    },
    "&:hover": {
        color: theme.palette.primary.light
    },
    "&::before": {
        content: "''",
        display: "block",
        position: "absolute",
        left: 0,
        bottom: 0,
        width: "0px",
        height: "2px",
        background: theme.palette.primary.main,
        borderRadius: "5px",
        transition: "all .5s ease-out",
        "&.active": {
            width: "100%"
        }
    },
    "&:hover::before": {
        width: "100%",
        transition: "all .5s ease-in"
    }
}));
const SideBarButtonWrapper = styled(Box)(() => ({
    display: "flex",
    flexDirection: "column",
    maxWidth: 200,
    margin: "auto"
}));
const SideButton = styled(NavButton)(({theme}) => ({
    border: `2px solid ${theme.palette.primary.main}`,
    color: theme.palette.primary.dark,
    width: "100%",
    "&:not(:first-of-type)": {
        marginLeft: 0,
        marginTop: 20
    },
    "&:hover": {
        color: "#fff",
        background: theme.palette.primary.main,
        border: `2px solid ${theme.palette.primary.main}`,
        transition: "0.3s ease-in-out",
        boxShadow: theme.customShadows.primary
    }
}));
const PageLink = styled("a")(() => ({
    textDecoration: "none",
    "&:not(:first-of-type)": {
        marginTop: 20
    }
}));
