import React, {useState} from "react";
import {Box, Button, Container, Grid, Typography} from "@mui/material";
import {Link as LinkS, animateScroll as scroll} from "react-scroll";
import {styled} from "@mui/material/styles";
import {Icon} from "@iconify/react";
import {MotionInView, varFadeInDown} from "../animate";
import {ColumnWrapper} from "../components";
import ShapeA from "../hero/component/shape-a";
import ShapeB from "../hero/component/shape-b";

const Footer = () => {
    const [email, setEmail] = useState("");
    const toggleHome = () => {
        scroll.scrollToTop();
    };
    return (
        <FooterStyle>
            <Container maxWidth="lg">
                <FooterGrid container>
                    <Grid item xs={12} md={8}>
                        <MotionInView variants={varFadeInDown}>
                            <FooterWrapper>
                                <FooterLogoContainer>
                                    <ColumnWrapper>
                                        <FooterLogo to="/" onClick={toggleHome}>
                                            MetaFaceWorld
                                        </FooterLogo>
                                    </ColumnWrapper>
                                </FooterLogoContainer>
                                <FooterLinksContainer>
                                    <FooterLinksItem>
                                        <FooterLinksTitle>
                                            {" "}
                                            Company
                                        </FooterLinksTitle>
                                        <FooterLinks
                                            to="about"
                                            smooth={true}
                                            duration={500}
                                            spy={true}
                                            exact="true"
                                            offset={-50}>
                                            About us
                                        </FooterLinks>
                                        <FooterLinks
                                            to="features"
                                            smooth={true}
                                            duration={500}
                                            spy={true}
                                            exact="true"
                                            offset={-50}>
                                            Why MetaFaceWorld?
                                        </FooterLinks>
                                    </FooterLinksItem>
                                    <FooterLinksItem>
                                        <FooterLinksTitle>
                                            {" "}
                                            Useful Links
                                        </FooterLinksTitle>
                                        <FooterLink href="/auth/login">
                                            <LinkIcon icon="akar-icons:bitcoin-fill" />
                                            Buy and sell
                                        </FooterLink>
                                        <FooterLink href="/auth/login">
                                            <LinkIcon icon="uil:exchange" />
                                            Exchange
                                        </FooterLink>
                                    </FooterLinksItem>
                                </FooterLinksContainer>
                            </FooterWrapper>
                        </MotionInView>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <NewsLetterBox>
                            {" "}
                            <FooterLinksTitle> NewsLetter </FooterLinksTitle>
                            <BoxInputWrapper
                                action="https://metafaceworldroadmap.us21.list-manage.com/subscribe/post?u=5a19f2a4535f397cd841fbdc3&amp;id=dff588a002&amp;f_id=00455ae1f0"
                                method="post"
                                id="mc-embedded-subscribe-form"
                                name="mc-embedded-subscribe-form"
                                target="_blank"
                                novalidate>
                                <BoxInput
                                    placeholder="Enter your email"
                                    type="email"
                                    value={email}
                                    onChange={(e) => {
                                        setEmail(e.target.value);
                                    }}
                                    name="EMAIL"
                                    id="mce-EMAIL"
                                />
                                <BoxInputButton
                                    variant="contained"
                                    type="submit"
                                    value="Subscribe"
                                    name="subscribe"
                                    id="mc-embedded-subscribe">
                                    <PaperPlane icon="bx:bxs-paper-plane" />
                                </BoxInputButton>
                            </BoxInputWrapper>
                        </NewsLetterBox>
                    </Grid>
                </FooterGrid>
            </Container>
            <ShapeAContainer>
                <ShapeA />
            </ShapeAContainer>
            <ShapeBContainer>
                <ShapeB />
            </ShapeBContainer>
        </FooterStyle>
    );
};
export default Footer;

const FooterStyle = styled(Box)(({theme}) => ({
    width: "100%",
    height: "fit-content",
    boxSizing: "border-box",
    overflow: "hidden",
    position: "relative",
    background: `linear-gradient(to top right, ${theme.palette.primary.main} 0%,${theme.palette.primary.darker} 100%)`,
    paddingBottom: 0,
    padding: "100px 0",
    [theme.breakpoints.down("md")]: {
        marginTop: 50,
        padding: "50px 10px"
    }
}));
const FooterGrid = styled(Grid)(() => ({
    width: "100%",
    alignItems: "center",
    margin: "0 auto",
    position: "relative",
    zIndex: 2
}));
const FooterWrapper = styled(Box)(({theme}) => ({
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
        flexDirection: "column"
    }
}));
const FooterLogoContainer = styled(Box)(({theme}) => ({
    maxWidth: 200,
    [theme.breakpoints.down("sm")]: {
        maxWidth: "100%",
        textAlign: "center",
        margin: "auto"
    }
}));
const FooterLogo = styled(LinkS)(({theme}) => ({
    color: "#fff",
    fontFamily: theme.typography.fontFamily,
    justifySelf: "start",
    cursor: "pointer",
    textDecoration: "none",
    fontSize: "2rem",
    display: "flex",
    alignItems: "center",
    marginBottom: 25,
    fontWeight: "bold",
    [theme.breakpoints.down("md")]: {
        fontSize: "1.6rem",
        marginBottom: 15
    },
    [theme.breakpoints.down("sm")]: {
        justifyContent: "center"
    }
}));
const FooterLinksContainer = styled(Box)(({theme}) => ({
    display: "flex",
    marginLeft: 150,
    [theme.breakpoints.down("lg")]: {
        marginLeft: 80
    },
    [theme.breakpoints.down("md")]: {
        paddingTop: 32
    },
    [theme.breakpoints.down("sm")]: {
        marginLeft: 0,
        width: "100%",
        justifyContent: "center"
    }
}));
const FooterLinksItem = styled(Box)(() => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    textAlign: "left",
    boxSizing: "border-box",
    color: "#fff",
    "&:not(:first-of-type)": {
        paddingLeft: 50
    }
}));
const FooterLinksTitle = styled(Typography)(({theme}) => ({
    fontSize: 18,
    fontWeight: "bold",
    marginBottom: 25,
    color: theme.palette.primary.light,
    [theme.breakpoints.down("sm")]: {
        fontSize: 16
    }
}));
const FooterLink = styled("a")(({theme}) => ({
    fontFamily: theme.typography.fontFamily,
    color: "#fff",
    display: "flex",
    alignItems: "center",
    textDecoration: "none",
    marginBottom: "0.5rem",
    fontSize: 16,
    "&:hover": {
        color: theme.palette.primary.light,
        transition: "0.3s ease-out",
        cursor: "pointer"
    },
    [theme.breakpoints.down("sm")]: {
        fontSize: 14
    }
}));
const FooterLinks = styled(LinkS)(({theme}) => ({
    fontFamily: theme.typography.fontFamily,
    color: "#fff",
    display: "flex",
    alignItems: "center",
    textDecoration: "none",
    marginBottom: "0.5rem",
    fontSize: 16,
    "&:hover": {
        color: theme.palette.primary.light,
        transition: "0.3s ease-out",
        cursor: "pointer"
    },
    [theme.breakpoints.down("sm")]: {
        fontSize: 14
    }
}));
const LinkIcon = styled(Icon)(() => ({
    color: "#fff",
    fontSize: 20,
    marginRight: 10
}));
const NewsLetterBox = styled(Box)(({theme}) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    [theme.breakpoints.down("md")]: {
        marginTop: 35
    }
}));
const BoxInputWrapper = styled("form")(({theme}) => ({
    width: "100%",
    [theme.breakpoints.down("md")]: {
        width: "50%"
    },
    [theme.breakpoints.down("sm")]: {
        width: "100%"
    }
}));
const BoxInput = styled("input")(({theme}) => ({
    outline: "none",
    padding: "0 25px",
    paddingRight: "30%",
    border: "none",
    borderRadius: 5,
    height: 65,
    width: "100%",
    background: theme.palette.background.default,
    transition: ".3s",
    fontSize: 16,
    position: "relative",
    color: theme.palette.text.primary,
    "&::placeholder": {
        color: theme.palette.text.secondary,
        fontFamily: theme.typography.fontFamily
    }
}));
const BoxInputButton = styled(Button)(({theme}) => ({
    position: "absolute",
    padding: "0px 10px",
    height: 65,
    lineHeight: 55,
    right: 0,
    borderRadius: 5,
    background: `linear-gradient(to left, ${theme.palette.primary.main}, ${theme.palette.primary.light})`,
    [theme.breakpoints.down("md")]: {
        right: "50%"
    },
    [theme.breakpoints.down("sm")]: {
        right: 0
    }
}));
const PaperPlane = styled(Icon)(() => ({
    color: "#fff",
    fontSize: 30
}));
const ShapeAContainer = styled(Box)(({theme}) => ({
    position: "absolute",
    top: "20%",
    left: "13%",
    [theme.breakpoints.down("md")]: {
        top: "5%",
        left: "-3%"
    }
}));
const ShapeBContainer = styled(Box)(({theme}) => ({
    position: "absolute",
    bottom: "10%",
    right: "10%",
    [theme.breakpoints.down("md")]: {
        bottom: "5%",
        right: "-3%"
    }
}));
