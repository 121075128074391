import React from "react";
import {Box, Container, Grid} from "@mui/material";
import {
    CenterWrapper,
    ColumnWrapper,
    ImageAnimation,
    Line,
    PageButton,
    RootStyle,
    SubTitle,
    Title
} from "../components";
import aboutDark from "../../../assets/images/aboutDark.png";
import aboutLight from "../../../assets/images/aboutLight.png";
import {styled} from "@mui/material/styles";
import {MotionInView, varFadeInUp} from "../animate";
import ShapeA from "../shapes/shape-a";
import ShapeB from "../shapes/shape-b";
import useSettings from "../../../hooks/useSettings";

const About = () => {
    const {themeMode} = useSettings();
    const isLight = themeMode === "light";
    return (
        <RootStyle id="about">
            <Container maxWidth="lg">
                <Grid container alignItems="center">
                    <Grid item xs={12} md={6}>
                        <AnimationWrapper>
                            <ImageAnimation/>
                            {isLight ? (
                                <AboutImage src={aboutLight}/>
                            ) : (
                                <AboutImage src={aboutDark}/>
                            )}
                        </AnimationWrapper>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <MotionInView variants={varFadeInUp}>
                            <ColumnWrapper>
                                <AboutTitle>About Us</AboutTitle>
                                <AboutLine/>
                                <AboutSubTitle>
                                        Welcome to MetaFaceWorld Exchange- Uniting a Global Community!
                                </AboutSubTitle>
                                    <AboutParagraph>
                                        MetaFaceWorld is an innovative online platform that brings together individuals
                                        from around the world to foster meaningful connections and celebrate the rich
                                        tapestry of human experiences. Drawing inpiration from global infomrmation
                                        resources, we have built a comprehensive ecosystem that offers unique Crypto,
                                        NFT, Gaming, and Exchange experiences to our community members.
                                    </AboutParagraph>
                                    <AboutParagraph>
                                        Explore our roadmap to discover upcoming features, enhancements, and innovations
                                        that will elevate your MetaFaceWorld experience. We are committed to
                                        continuously improving our platform, expanding our offerings, and providing an
                                        exceptional space for our community to thrive.
                                    </AboutParagraph>
                                    <AboutParagraph>
                                        Join us on this extraordinary journey of connection, self-expression, and
                                        personal discovery. Become a part of MetaFaceWorld Ecosystem, and experience the
                                        magic of a global community coming together.
                                    </AboutParagraph>
                                    <AboutParagraph>
                                    METAFACEWORLD UNITING A GLOBAL COMMUNITY
                                    </AboutParagraph>
                                <ButtonWrapper>
                                    <PageButton href="/auth/register">
                                        Get started
                                    </PageButton>
                                </ButtonWrapper>
                            </ColumnWrapper>
                        </MotionInView>
                    </Grid>
                </Grid>
            </Container>
            <ShapeAContainer>
                <ShapeA/>
            </ShapeAContainer>
            <ShapeBContainer>
                <ShapeB/>
            </ShapeBContainer>
        </RootStyle>
    );
};

export default About;
const AboutTitle = styled(Title)(({theme}) => ({
    textAlign: "left",
    [theme.breakpoints.down("md")]: {
        textAlign: "center",
        marginTop: 40
    }
}));
const AboutSubTitle = styled(SubTitle)(({theme}) => ({
    textAlign: "left",
    [theme.breakpoints.down("md")]: {
        textAlign: "center"
    }
}));
const AboutParagraph = styled("p")(({theme}) => ({
    textAlign: "left",
    fontWeight: 400,
    [theme.breakpoints.down("md")]: {
        textAlign: "center"
    }
}));
const AboutLine = styled(Line)(({theme}) => ({
    margin: "30px 0px 0px",
    [theme.breakpoints.down("md")]: {
        margin: "30px auto"
    }
}));
const ButtonWrapper = styled(CenterWrapper)(({theme}) => ({
    paddingTop: 40,
    justifyContent: "flex-start",
    zIndex: 2,
    [theme.breakpoints.down("md")]: {
        justifyContent: "center"
    }
}));
const AnimationWrapper = styled(Box)(({theme}) => ({
    maxWidth: 600,
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
        maxWidth: "100%",
        marginBottom: 60
    },
    [theme.breakpoints.down("sm")]: {
        marginBottom: 40,
        marginTop: -80
    }
}));
const AboutImage = styled("img")(({theme}) => ({
    width: "90%",
    boxShadow: theme.customShadows.z12,
    borderRadius: theme.shape.borderRadiusSm,
    marginLeft: "-20%",
    zIndex: 2,
    [theme.breakpoints.down("md")]: {
        marginLeft: 0,
        width: "80%",
        marginTop: 100
    },
    [theme.breakpoints.down("sm")]: {
        marginTop: 140
    }
}));
const ShapeAContainer = styled(Box)(({theme}) => ({
    position: "absolute",
    top: "5%",
    left: "35%",
    [theme.breakpoints.down("md")]: {
        left: "-3%"
    }
}));
const ShapeBContainer = styled(Box)(({theme}) => ({
    position: "absolute",
    bottom: "5%",
    right: "10%",
    transform: "rotate(45deg)",
    [theme.breakpoints.down("md")]: {
        right: "-3%"
    }
}));
