import * as React from "react";
import {styled, useTheme} from "@mui/material/styles";

function ShapeB(props) {
    const theme = useTheme();
    const background = theme.palette.primary.main;
    return (
        <Svg
            viewBox="0 0 163.1 208.1"
            xmlns="http://www.w3.org/2000/svg"
            {...props}>
            <path
                d="M56.6 208c-101.4-4.4-34.9-46.4-33.6-103.8C24.4 46.8-49.9-2.5 61.5.1c57.4 1.3 102.9 49 101.6 106.4-1.4 57.4-49.1 104-106.5 101.5z"
                fill={background}
                opacity={0.1}
            />
        </Svg>
    );
}

export default ShapeB;

const Svg = styled("svg")(({theme}) => ({
    height: 210,
    width: 166,
    [theme.breakpoints.down("md")]: {
        width: 120
    }
}));
