// ----------------------------------------------------------------------

const shape = {
    borderRadius: 8,
    borderRadiusSm: 12,
    borderRadiusMd: 16,
    borderRadiusLg: 44
};

export default shape;
