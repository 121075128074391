import * as React from "react";
import {styled, useTheme} from "@mui/material/styles";

function ShapeA(props) {
    const theme = useTheme();
    const background = theme.palette.primary.lighter;
    return (
        <Svg
            height={174}
            viewBox="0 0 190 174"
            width={190}
            xmlns="http://www.w3.org/2000/svg"
            {...props}>
            <path
                d="M105.823 6.217c45.266-23.48 88.777 23.113 83.807 80.972-4.387 51.051-39.776 105.983-83.807 80.261C68.181 145.462 0 148.129 0 97.133S41.226 39.724 105.823 6.217z"
                fill={background}
                fillRule="evenodd"
                opacity={0.1}
            />
        </Svg>
    );
}

export default ShapeA;

const Svg = styled("svg")(({theme}) => ({
    height: 210,
    width: 190,
    [theme.breakpoints.down("md")]: {
        width: 140
    }
}));
