import * as React from "react";
import {styled, useTheme} from "@mui/material/styles";

function EasySvg(props) {
    const theme = useTheme();
    const color = theme.palette.primary.main;
    return (
        <Svg
            height="373pt"
            viewBox="-2 0 373 373.19339"
            width="373pt"
            fill={color}
            xmlns="http://www.w3.org/2000/svg"
            {...props}>
            <path d="M110.965 298.879l-23.606-39.285c-3.222-5.367-10.23-7.121-15.593-3.88a11.298 11.298 0 00-5.192 6.974c-.738 2.933-.273 5.992 1.305 8.62l11.7 19.477-11.7 7.027-11.703-19.48c-3.461-5.75-4.469-12.484-2.844-18.965 1.621-6.46 5.672-11.914 11.406-15.36 11.809-7.085 27.219-3.265 34.328 8.556l23.606 39.292zm0 0" />
            <path d="M187.004 356.734c-32.23 0-46.043-5.113-50.598-7.402L57.7 338.934 13.32 315.05c-11.68-6.285-16.394-20.551-11-32.418l-.047-.028.766-1.433.047.027c6.879-11.074 21.355-15.11 33.05-8.926l29.118 15.368 66.281-1.207 9.68-6.086c-12.563-7.235-31.05-19.934-34.09-33.453-1.23-5.461 0-10.676 3.559-15.09 16.078-19.965 87.437 15.93 119.097 33.316l74.406.168-.035 13.652-77.902-.18-1.531-.847c-65.88-36.379-100.196-41.523-103.406-37.531-.934 1.152-1.176 2.14-.868 3.512 2.04 9.093 23.07 22.91 37.348 29.808l11.094 5.36-33.301 20.96-73.598 1.34-32.222-17.008c-5.325-2.82-11.891-1.007-15.043 3.997-2.403 5.378-.239 11.824 5.066 14.68l42.18 22.69 79.02 10.45 1.292.84c.16.086 31.824 17.312 157.586-7.203l2.613 13.402c-53.011 10.32-90 13.523-115.476 13.523zm0 0" />
            <path d="M362.285 373.195h-61.402a6.828 6.828 0 01-6.828-6.828V250.195a6.828 6.828 0 016.828-6.828h61.402a6.828 6.828 0 016.828 6.828v116.172a6.828 6.828 0 01-6.828 6.828zm-54.574-13.656h47.75V257.02h-47.75zm0 0M179.852 213.906h-20.547a6.822 6.822 0 01-6.828-6.824 6.825 6.825 0 016.828-6.828h20.547c51.445 0 93.296-41.852 93.296-93.3 0-51.446-41.851-93.302-93.296-93.302h-15.93c-3.77 0-6.824-3.05-6.824-6.824S160.152 0 163.922 0h15.93c58.976 0 106.953 47.98 106.953 106.953s-47.977 106.953-106.953 106.953zm0 0" />
            <path d="M161.645 213.906c-58.973 0-106.95-47.98-106.95-106.953S102.672 0 161.645 0c58.976 0 106.957 47.98 106.957 106.953s-47.98 106.953-106.957 106.953zm0-200.254c-51.442 0-93.293 41.856-93.293 93.301 0 51.45 41.851 93.3 93.293 93.3 51.449 0 93.3-41.85 93.3-93.3 0-51.445-41.851-93.3-93.3-93.3zm0 0" />
            <path d="M141.836 69.227c0-.72.48-1.192 1.195-1.192h30.2c17.19 0 25.304 8.594 25.304 21.606 0 9.195-4.293 14.441-9.426 17.187v.238c5.016 1.914 10.86 8.36 10.86 18.27 0 16.11-10.145 23.871-27.934 23.871h-29.004c-.715 0-1.195-.48-1.195-1.191zm31.039 32.585c7.16 0 11.582-4.292 11.582-10.62 0-6.446-4.422-10.747-11.582-10.747h-16.238c-.473 0-.715.243-.715.715v19.938c0 .476.242.715.715.715zm-16.238 34.981h16.953c7.879 0 12.293-4.54 12.293-11.34 0-6.687-4.414-11.226-12.293-11.226h-16.953c-.473 0-.715.238-.715.718v21.13c0 .48.242.718.715.718zm0 0" />
            <path d="M163.922 79.648a6.824 6.824 0 01-6.824-6.828V59.168a6.827 6.827 0 1113.652 0V72.82a6.828 6.828 0 01-6.828 6.828zm0 0M163.922 161.566a6.824 6.824 0 01-6.824-6.828v-6.824a6.827 6.827 0 1113.652 0v6.824a6.828 6.828 0 01-6.828 6.828zm0 0" />
        </Svg>
    );
}

export default EasySvg;

export const Svg = styled("svg")(() => ({
    width: 80,
    height: 80
}));
