import React from "react";
import {styled} from "@mui/material/styles";
import {
    Box,
    Button,
    IconButton,
    List,
    Toolbar,
    useScrollTrigger
} from "@mui/material";
import {animateScroll as scroll, Link as ScrollLink} from "react-scroll";
import {Icon} from "@iconify/react";
import Logo from "assets/images/logo.png"

const Header = ({toggle, isOpen}) => {
    const toggleHome = () => {
        scroll.scrollToTop();
    };

    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 0
    });
    return (
        <Navbar disableGutters isopen={isOpen} scrollNav={trigger}>
            <LogoContainer onClick={toggleHome}>
                <Box component="img" src={Logo} alt="logo" width={120}/>
            </LogoContainer>
            <Box sx={{flexGrow: 0.3}}></Box>
            <Box sx={{display: {xs: "none", md: "block"}}}>
                <NavItemWrapper>
                    <NavLinksContainer>
                        {navItems.map((navItem) => (
                            <NavLinks
                                scrollNav={trigger}
                                key={navItem.id}
                                to={navItem.idm}
                                smooth={true}
                                duration={500}
                                spy={true}
                                exact="true"
                                offset={-50}>
                                {navItem.name}
                            </NavLinks>
                        ))}
                    </NavLinksContainer>
                    <Box
                        sx={{
                            position: "absolute",
                            right: "5%"
                        }}>
                        <PageLink href="/auth/register" scrollNav={trigger}>
                            <NavButton scrollNav={trigger} variant="outlined">
                                {" "}
                                Sign up{" "}
                            </NavButton>
                        </PageLink>
                        <PageLink href="/auth/login">
                            <NavButton scrollNav={trigger} variant="outlined">
                                Login
                            </NavButton>
                        </PageLink>
                    </Box>
                </NavItemWrapper>
            </Box>
            <Box sx={{display: {xs: "block", md: "none"}}}>
                <NavItemWrapper>
                    <NavIconButton onClick={toggle}>
                        <NavIcon icon="ri:menu-3-fill" scrollNav={trigger} />
                    </NavIconButton>
                </NavItemWrapper>
            </Box>
        </Navbar>
    );
};
export default Header;

const Navbar = styled(Toolbar)(({theme, scrollNav}) => ({
    height: "80px",
    width: "100%",
    position: "fixed",
    background: scrollNav ? theme.palette.background.default : "transparent",
    boxShadow: scrollNav ? theme.customShadows.z12 : "none",
    top: 0,
    opacity: scrollNav ? 0.9 : "none",
    left: 0,
    transition: ".35s ease-in-out",
    zIndex: 999
}));
const LogoContainer = styled(Box)(({theme}) => ({
    display: "flex",
    alignItems: "center",
    position: "relative",
    marginLeft: "10%",
    cursor: "pointer",
    [theme.breakpoints.down("lg")]: {
        marginLeft: "5%"
    }
}));

const NavItemWrapper = styled(Box)(() => ({
    display: "flex",
    alignItems: "center"
}));
const NavLinksContainer = styled(List)(({theme}) => ({
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("lg")]: {
        marginLeft: -80
    }
}));
const NavLinks = styled(ScrollLink)(({theme, scrollNav}) => ({
    fontFamily: theme.typography.fontFamily,
    position: "relative",
    padding: "27.6px 20px",
    color: scrollNav ? theme.palette.text.primary : theme.palette.grey[400],
    cursor: "pointer",
    fontSize: 14,
    "&.active": {
        color: scrollNav ? theme.palette.primary.main : "#fff",
        fontWeight: "bold"
    },
    "&:hover": {
        color: scrollNav
            ? theme.palette.primary.light
            : theme.palette.grey[500],
        transition: "0.4s all ease-in-out"
    },
    [theme.breakpoints.down("lg")]: {
        padding: "25px 10px"
    }
}));
export const NavButton = styled(Button)(({theme, scrollNav}) => ({
    padding: "6px 30px",
    border: scrollNav
        ? `2px solid ${theme.palette.primary.main}`
        : `2px solid rgba(255,255,255,0.3)`,
    color: scrollNav ? theme.palette.primary.main : theme.palette.grey[400],
    letterSpacing: 1.3,
    fontSize: 14,
    textTransform: "uppercase",
    fontWeight: "600",
    transition: "0.4s all ease-in-out",
    borderRadius: theme.shape.borderRadiusLg,
    "&:hover": {
        color: "#fff",
        background: theme.palette.primary.main,
        border: `2px solid ${theme.palette.primary.main}`,
        transition: "0.3s ease-in-out",
        boxShadow: theme.customShadows.primary
    }
}));
const NavIconButton = styled(IconButton)(() => ({
    position: "absolute",
    right: "10%"
}));
const NavIcon = styled(Icon)(({theme, scrollNav}) => ({
    fontSize: "30px",
    color: scrollNav ? theme.palette.primary.dark : "#fff"
}));
const PageLink = styled("a")(() => ({
    textDecoration: "none",
    "&:not(:first-of-type)": {
        marginLeft: 15
    }
}));

export const navItems = [
    {id: 1, idm: "home", name: "Home"},
    {id: 2, idm: "about", name: "About"},
    {id: 3, idm: "features", name: "Features"},
    {id: 4, idm: "faqs", name: "FAQs"},
    {id: 5, idm: "contact", name: "Contact"}
];
