import btcLogo from "assets/images/bitcoin-btc-logo.png";
import ethLogo from "assets/images/ethereum-eth-logo.png";
import ltcLogo from "assets/images/litecoin-ltc-logo.png";
import bchLogo from "assets/images/bitcoin-cash-bch-logo.png";
import dashLogo from "assets/images/dash-dash-logo.png";
import bnbLogo from "assets/images/bnb-bnb-logo.png";
import usdtLogo from "assets/images/tether-usdt-logo.png";
import busdLogo from "assets/images/binance-usd-busd-logo.png";
import shibLogo from "assets/images/shiba-inu-shib-logo.png";
import linkLogo from "assets/images/chainlink-link-logo.png";
import omgLogo from "assets/images/omg.png";

export const coins = [
    {id: "bitcoin", symbol: "BTC", image: btcLogo},
    {id: "ethereum", symbol: "ETH", image: ethLogo},
    {id: "litecoin", symbol: "LTC", image: ltcLogo},
    {id: "bitcoin-cash", symbol: "BCH", image: bchLogo},
    {id: "dash", symbol: "DASH", image: dashLogo},
    {id: "binance-coin", symbol: "bnb", image: bnbLogo},
    {id: "tether", symbol: "USDT", image: usdtLogo},
    {id: "binance-usd", symbol: "BUSD", image: busdLogo},
    {id: "shiba-inu", symbol: "SHIB", image: shibLogo},
    {id: "chainlink", symbol: "LINK", image: linkLogo},
    {id: "omg", symbol: "OMG", image: omgLogo}
];
