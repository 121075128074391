import React, {useState} from "react";
import {
    About,
    Contact,
    CryptoSlide,
    Explore,
    Faq,
    Footer,
    Header,
    Hero,
    Services,
    Settings,
    Sidebar
} from "./components";
import {useMediaQuery} from "@mui/material";
import {useTheme} from "@mui/material/styles";

const Landing = () => {
    const theme = useTheme();
    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => {
        setIsOpen(!isOpen);
    };
    const isMd = useMediaQuery(theme.breakpoints.up("md"), {
        defaultMatches: true
    });
    const open = isMd ? false : isOpen;
    return (
        <React.Fragment>
            <Header toggle={toggle} />
            <Sidebar toggle={toggle} open={open} />
            <Hero />
            <CryptoSlide />
            <About />
            <Services />
            <Explore />
            <Faq />
            <Contact />
            <Footer />
            <Settings />
        </React.Fragment>
    );
};

export default Landing;
