import * as React from "react";
import {Svg} from "./easy";
import {useTheme} from "@mui/material/styles";

function RocketSvg() {
    const theme = useTheme();
    const color = theme.palette.primary.main;
    return (
        <Svg
            xmlns="http://www.w3.org/2000/svg"
            enableBackground="new 0 0 512 512"
            fill={color}
            viewBox="0 0 512 512">
            <path d="M497.7,19.8c-1.1-2.5-3.1-4.5-5.5-5.5c-1.3-0.6-129.3-54.4-234.6,50.8c-20.1,20.1-39.5,43.8-57.3,68.8l-24.3-5.6c-36.7-8.5-74.5,2.3-101.2,28.9L8.5,223.6c-17.6,17.6-6.3,47.5,18.2,49.4l93.4,7.5c-4.1,10.9-7.6,21.6-9.6,28.7c-1.8,6.3-1.5,12.8,0.4,18.8c-16.9,4.6-32.3,13-41,21.7c-19.2,19.1-36.3,102.8-39.5,119.4c-1.4,8,5.7,14.1,12.5,12.5c16.6-3.3,100.3-20.4,119.4-39.5c9.1-9.1,17.3-24.7,21.8-41c6.4,2,12.9,2,18.8,0.3h0.1c7.1-2,17.5-5.3,28.6-9.6l7.5,93.4c1.9,24.5,31.9,35.7,49.4,18.2l66.4-66.4c26.6-26.6,37.5-64.5,28.9-101.2l-5.6-24.3c25-17.8,48.7-37.2,68.8-57.3C552.2,149.1,498.3,21.1,497.7,19.8z M23.7,238.7L90,172.3c21.5-21.5,51.9-30.1,81.2-23.2l16.1,3.7c-23.9,35.9-44.1,73.5-58.7,106.9l-100.2-8C21.9,251.1,19.1,243.2,23.7,238.7z M147.2,427.1c-9.3,9.3-55.4,22.2-92.6,30.4c8.1-37.3,21-83.3,30.4-92.6c8.1-8.2,23.9-15.3,39.3-18.1l41,41c-0.1,0.4-0.1,0.8-0.2,1.3C162.1,403.8,154.8,419.5,147.2,427.1z M185.6,377.8l-51.4-51.4c-3-3-4.2-7.5-3.1-11.5c0.9-3.1,2.6-8.9,4.8-15.4l76.6,76.6c-4.6,1.6-10.7,3.5-15.4,4.8C193.1,382.1,188.7,380.9,185.6,377.8z M362.9,340.8c6.8,29.5-1.8,59.8-23.2,81.2l-66.4,66.4c-4.6,4.6-12.4,1.7-13-4.7l-8-100.2c33.4-14.5,71-34.8,106.9-58.7L362.9,340.8z M234.4,367.8l-90.3-90.3c25.5-62.3,74.2-143,128.5-197.3c62-62,143.5-71.3,207-47.9c7.5,21,37.3,121.9-47.9,207.1C377.4,293.7,296.7,342.4,234.4,367.8z" />
            <path d="M312.6,95.4c-28.7,28.7-28.7,75.2,0,104c28.7,28.7,75.2,28.7,104,0c28.7-28.7,28.7-75.3,0-104C387.8,66.7,341.3,66.7,312.6,95.4z M401.5,184.3c-20.4,20.4-53.4,20.4-73.8,0s-20.4-53.4,0-73.8s53.4-20.4,73.8,0C421.8,130.9,421.8,164,401.5,184.3z" />
        </Svg>
    );
}

export default RocketSvg;
